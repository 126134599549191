


















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { UserStores } from '@/store/users';
import { FolderItem } from '@/models/miscellaneous';
import FolderNavigation from '@/components/management/FolderNavigation.vue';
import ManagementList from '@/components/management/ManagementList.vue';
import { CourseListItem } from '@/models/management';
import moment from 'moment';
import { ProgressStores } from '@/store/progress';
import { StudentItem } from '@/models/progress';
@Component({
    components: { 
        Page, 
        BackButtonLayout, 
        FolderNavigation,
        ManagementList,
    },
})
export default class StudentCourses extends Vue {
    //Stores
    userDetailStore = UserStores.detail;
    progressCourseStore = ProgressStores.course;
    progressStateStore = ProgressStores.state;
    //UI State
    studentName = ``;
    currentFolders: FolderItem[] = [
        {
            id: -1,
            name: `Courses`
        }
    ];
    currentCourses: CourseListItem[] = [];

    get folders() {
        return this.currentFolders;
    }

    get layoutTitle() {
        return this.studentName;
    }

    get backRoute() {
        return `/progress`;
    }

    get courses() {
        return this.currentCourses;
    }

    mounted() {
        if (!this.progressStateStore.studentState) {
            this.userDetailStore.retrieveUserById(Number(this.$route.params.id));
        } else {
            this.studentName = this.progressStateStore.studentState.name;
        }
        this.progressCourseStore.retrieveStudentPaidCourses(Number(this.$route.params.id));
    }

    @Watch(`userDetailStore.fetchedUserResponse`)
    updateUserData() {
        if (this.userDetailStore.fetchedUserResponse) {
            this.studentName = `${this.userDetailStore.fetchedUserResponse.firstname} ${this.userDetailStore.fetchedUserResponse.lastname}`
            const payload: StudentItem = {
                id: this.userDetailStore.fetchedUserResponse.id,
                name: this.studentName,
                email: this.userDetailStore.fetchedUserResponse.email,
                mobile: this.userDetailStore.fetchedUserResponse.mobile,
                birthday: this.userDetailStore.fetchedUserResponse.birthday,
                lastlogin: '',
                role: this.userDetailStore.fetchedUserResponse.role,
            };
            this.progressStateStore.setStudentState(payload);
        }
    }

    @Watch(`progressCourseStore.response`)
    updateCourseData() {
        this.currentCourses = [];
        if (this.progressCourseStore.response.length > 0) {
            this.progressCourseStore.response.forEach((data) => {
                this.currentCourses.push({
                    id: data.id,
                    courseId: data.sku.toString(),
                    name: `${data.courseName} - ${data.form.name} - ${moment(data.startDate).format(`YYYY`)} - ${data.teacher.firstname} ${data.teacher.lastname}`,
                    numberOfCourses: -1,
                });
            });
        }
    }

    navigateToDetailedSection(data: CourseListItem) {
        this.progressStateStore.setCourseState(data);
        this.$router.push({
            path: `/progress/${this.$route.params.id}/courses/${data.id}`
        });
    }

}
